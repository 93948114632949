import * as React from "react"
import Boxed from "../Boxed";
import SendButton from "images/send-btn.svg";
import "./style.scss";

const SupportForm = ({title1, title2, title3, placeholderInput, placeholderText, successMessage1, successMessage2}) => {
  const [sent, setSent] = React.useState(false);
  const [formComplete, setFormComplete] = React.useState(false);
  const [email, setEmail] = React.useState();
  const [message, setMessage] = React.useState();

  const handleSend = () => {
    if (!formComplete) {
      return;
    }

    const data = {
      email,
      content: message
    };
    fetch("https://web-gateway.paramla.com/mail", {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    })
    setSent(true);
  }

  const checkFormComplete = () => {
    const getValue = () => {
      return !(!email || !message);

    }
    setFormComplete(getValue())
  }

  const handleInputChange = e => {
    setEmail(e.target.value)
    checkFormComplete();
  }
  const handleMessageChange = e => {
    setMessage(e.target.value)
    checkFormComplete();
  }

  if (sent) {
    return <Boxed>
      <div className="support-form-success">
        {successMessage1}
        <br/>
        {successMessage2}
      </div>
    </Boxed>
  }

  return <Boxed>
    <div className="support-title">
      <div className={title1.isBold ? "support-title--bold" : ""}>
        {title1.label}
      </div>
      <div className={title2.isBold ? "support-title--bold" : ""}>
        {title2.label}
      </div>
      <div className={title3.isBold ? "support-title--bold" : ""}>
        {title3.label}
      </div>
    </div>
    <div className="support-form">
      <form method="post" onSubmit={handleSend}>
        <div className="support-form-input-container">
          <input
            type="email"
            required
            className="support-form-input"
            placeholder={placeholderInput}
            onChange={handleInputChange}
          />
        </div>
        <div className="support-form-input-container">
          <textarea
            required
            className="support-form-input" rows={10}
            placeholder={placeholderText}
            onChange={handleMessageChange}
            onKeyUp={handleMessageChange}
          />
        </div>
        <div className="support-form-input-container">
          <input type="image" className={`support-form-btn${formComplete ? "support-form-btn--completed" : ""}`} src={SendButton} alt=""/>
        </div>
      </form>
    </div>
  </Boxed>;
}

export default SupportForm;
